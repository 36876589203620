import * as React from 'react';
import './styles.css';

type Props = {
    header: string,
    subheader: string,
    link: string,
    progerssInPerc: number,
}

const MenuItem = (props: Props) => {
  return (
    <li className="menu-item" >
      <span className="progress" style={{ width: (props.progerssInPerc || '') + '%' }} />
      <a href={`#${props.link}`}
        style={{ opacity: props.progerssInPerc > 0.1 && props.progerssInPerc < 100 ? 0.8 : 0.2 }}
        className={`menu-text-wrapper ${props.progerssInPerc > 0.1 ? 'section-highlight-text' : ''}`}>
        <span>{props.header}</span>
        {/* <span>{props.subheader}</span> */}
      </a>
    </li>
  );
};

export default MenuItem;
