import React from 'react';
import './startSectionStyles.css';

class StartSection extends React.Component {
  armorImgPath = 'https://storage.yandexcloud.net/arsenal-images/wear_armour/armour_filled.png';
  transperentImgPath = 'https://storage.yandexcloud.net/arsenal-images/wear_armour/armour_trasperent.png'
  startIconPath = 'https://storage.yandexcloud.net/arsenal-images/wear_armour/start_icon_1.png'

  render () {
    return (
      <div className="start-wear-armour-wrapper">
        <div className="start-wear-armour-wrapper-background" />
        <div className="start-wear-armour-img-wrapper">
          <img className="armour-start-icon" src={this.startIconPath} />
          <img className="armour-launch-img" src={this.armorImgPath} />
        </div>
        <div onClick={this.props.openModal} className="button"><span>ПРИМЕРЬ ДОСПЕХ</span></div>
      </div>
    );
  }
}

export default StartSection;
