/* eslint-disable max-len */

const text = {
  header: '23 мая 1842 года — 25-летие со дня бракосочетания Государя Императора Николая I.',
  caruselDescription: 'В 7 часов вечера с площадки Арсенала выступил рыцарский кортеж, состоявший из 16 дам и 16 кавалеров. Участники представления были одеты в подлинные средневековые доспехи из личной коллекции императора прошли по парку и остановилась у Александровского дворца. Здесь была карусель — вид конного состязания, пришедшего на смену рыцарским турнирам. Рыцари двигались по кругу и на всем скаку выполняли ряд упражнений.',
  caruselStoryOne: 'Государь, славившийся своей выносливостью на парадах, после одной из репетиций сказал: "Нет, такие проделки нашим старым костям не под силу, десять часов на маневрах меньше меня утомляют". Трудность усугублялась тем, что мужчины должны были надеть для участия в процессии настоящие средневековые латы, взятые из Царскосельского арсенала.',
  caruselStoryTwo: 'И лишь самый младший член процессии девятилетний великий князь Михаил Николаевич сохранил энергию к концу этого дня: "Когда кончился карусель, то поехали к Арсеналу и мне было ужасно весело".',
  storyCaption: "Л. Я. Сидорова. 'Никогда я царское село в таком блеске не видал...' Cемья императора Николая I в Царском Селе.",
  additionalText: `23 мая 1842 г., в день карусели, Ф. П. Литке со свойственным ему скептицизмом записал в дневнике: "Кавалерам латы даны были из Арсенала. Все это прибрать и пригнать в неделю, дамам сшить себе костюмы. С ног все сбились. Но это еще не главное: надо было видеть этих несчастных, когда они влезли в железные футляры, не на них сшитые, совершенные мученики, и в этом состоянии управлять лошадью. Кавалеристы записные признавались, что они совсем не покойны, тем более что и лошади не привыкли к таким фигурам. Государь, примеряя шлем свой, чуть не задохся".`,
};

export default text;
