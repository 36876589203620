import * as React from 'react';
import './styles.css';

const CaruselHeader = () => {
  return (
    <div className="carusel-header-wrapper" >
      <div className="carusel-header-text-wrapper">
        <h1>ЦАРСКОСЕЛЬСКАЯ КАРУСЕЛЬ</h1>
        <h3>1842</h3>
      </div>
      <div className="carusel-header-red-color-block" />
      <div className="carusel-header-yellow-color-block" />
    </div>
  );
};

export default CaruselHeader;
