import React from 'react';
import VideoArmour from './VideoArmour';

class VideoArmourContainer extends React.Component {
    state = {
      shouldPlayVideo: false,
      screenShot: null,
    }

    screenShotIconPath = 'https://storage.yandexcloud.net/arsenal-images/wear_armour/shoot_icon.png'
    downloadIconPath = 'https://storage.yandexcloud.net/arsenal-images/wear_armour/download_icon.png'

    renderScreenShot = (screenShot) => {
      this.setState({ screenShot });
    }

    createNewScreenShot = () => {
      this.setState({ screenShot: null });
    }

    convertBase64toFile = () => {
      return new File([this.state.screenShot], 'arsenal_armour.png', { type: 'image/png' });
    }

    render () {
      const { height, width } = this.state;

      // if (!this.state.shouldPlayVideo) {
      //   return <button className="video-container" onClick={() => {
      //     this.setState({ shouldPlayVideo: true });
      //   }}>Play Video</button>;
      // }

      if (this.state.screenShot) {
        return (
          <div style={{ height, width, position: 'relative' }}>
            <div className="screen-shot-container" style={{ height, width }}>
              <img style={{ height, width }}
                src={this.state.screenShot} />
            </div>
            <div className="screen-shot-buttons-wrapper">
              <a href={this.state.screenShot} download={this.convertBase64toFile()}
                onClick={this.createNewScreenShot} >
                <div className="create-screen-shot-button"
                >
                  <img src={this.downloadIconPath} />
                </div>
              </a>
              <div className="create-screen-shot-button"
                onClick={this.createNewScreenShot} >
                <img src={this.screenShotIconPath} />
              </div>
            </div>
          </div>
        );
      }

      return (
        <div className="video-container-wrapper">
          <div className="video-container">
            {/* <button onClick={() => {
              this.setState({ shouldPlayVideo: false });
            }}>Play Video</button> */}
            <VideoArmour
              renderScreenShot={this.renderScreenShot}
              height={this.props.height}
              width={this.props.width}
              screenShot={this.state.screenShot}
              screenShotIconPath={this.screenShotIconPath}
            />
          </div>
        </div>
      );
    }
}

export default VideoArmourContainer;
