import * as React from 'react';
import './styles.css';

type Props = {
    header: string,
    year: string,
}

class Header extends React.Component<Props> {
  render () {
    const { props } = this;
    return (
      // <div className="arsenal-header-wrapper" >
      <div className="arsenal-color-block" >
        <div className="arsenal-img-shadow" />
        <div className="arsenal-header-text">
          <h1>{props.header.toUpperCase()}</h1>
          <span>{props.years}</span>
        </div>
      </div>
      // </div>
    );
  }
}

export default Header;
