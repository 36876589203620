import * as React from 'react';
import './styles.css';
import Plx from 'react-plx';
import text from '../../text';

const HuntHorsesSecond = () => {
  return (
    <div className="hunt-horses-second-outter-wrapper">
      <div className="hunt-horses-second-left" />
      <div className="hunt-horses-second-right">
        <span>{text.huntMiddle}</span>
      </div>
      <Plx parallaxData={parallaxContainer} className="hunt-horses-dog-img">
        <img src={dogImg} alt="Собака породы борзая" />
      </Plx>
    </div>
  );
};

const parallaxContainer = [
  {
    start: 'self',
    duration: '80vh',
    properties: [
      {
        startValue: 70,
        endValue: 0,
        property: 'translateY',
        unit: '%',
      },
    ],
  },
];

const dogImg = 'https://storage.yandexcloud.net/arsenal-images/hunt-dogs.png';

export default HuntHorsesSecond;
