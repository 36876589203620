/* eslint-disable max-len */

const text = {
  huntStart: 'В Царском Селе, в центре зверинца назначался сборный пункт, где разбивались богато украшенные палатки. Охота началась в двенадцатом и кончилась в шестом часу вечера.',
  huntStartSecond: 'При прибытии императрицы Елизаветы на сборное место там уже находилось до семидесяти егерей в черкесском платье, в алых суконных кафтанах и в длинных камзолах с золотыми позументами; при них было более трехсот гончих и борзых собак.',
  huntMiddle: 'Охота началась по звуку призывных рогов. Великолепие этого охотничьего праздника усиливалось еще более участием богато одетых слуг и придворных лакеев. Лошади всадников блестели драгоценной сбруей.',
  huntEnd: 'По возвращении с охоты участникам был предложен роскошный обед в великолепной палатке. Во время застолья играла музыка. Императрица возвратилась в Петербург с этого праздника только в три часа ночи".',
  huntCaption: 'Ирина Палтусова, "Придворная охота в России".',
};

export default text;
