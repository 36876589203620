import * as React from 'react';
import './styles.css';
const elisa = 'https://storage.yandexcloud.net/arsenal-images/elisa.png';

type Props = {
    header: string,
    years: string,
}

const SectionHeader = (props: Props) => {
  return (
    <div className="eliza-header-wrapper">
      <img src={elisa} className="elisaveta-img" alt="Елизавета Петровна. Императрица" />
      <div className="colorBlockWrapper" >
        <div className="colorBlock colorBlockFront" >
          <div className="headerWrapper">
            <h1>{props.header.toUpperCase()}</h1>
            <h3>{props.years[0]} - {props.years[1]}</h3>
          </div>
        </div>
        <div className="colorBlock colorBlockBack" />
      </div>
    </div>
  );
};

export default SectionHeader;
