import * as React from 'react';
import './ExpandCaptionStyles.css';

type Props = {
    caption: string,
    additionalText?: string,
}

class ExpandCaption extends React.Component<Props> {
    captionImgPath = 'https://storage.yandexcloud.net/arsenal-images/caprion-icon.png'

    state = {
      isExpanded: false,
    }

    toggleExpand = () => {
      if (this.props.additionalText) {
        this.setState({ isExpanded: !this.state.isExpanded });
      }
    }

    renderAdditionalText = () => {
      if (this.state.isExpanded) {
        return (
          <span className="caption-additional-text">{this.props.additionalText}</span>
        );
      }
    }

    renderToggleButton = () => {
      if (this.props.additionalText) {
        return <img className="caption-img" src={this.captionImgPath} />;
      }
    }

    render () {
      const classNames = this.props.additionalText ? 'pointer caption-toggle' : 'caption-toggle';
      return (
        <div className="caption-block" >
          <div className={classNames} onClick={this.toggleExpand}>
            {this.renderToggleButton()}
            <div className="caption">
              <span>{this.props.caption}</span>
            </div>
          </div>
          {this.renderAdditionalText()}
        </div>
      );
    }
}

export default ExpandCaption;
