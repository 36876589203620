import React from 'react';
import './sliderStyles.css';
import ReactCSSTransitionGroup from 'react-addons-transition-group';

class Slider extends React.Component {
  generateItems () {
    const items = [];
    let level;
    for (let i = this.props.active - 2; i < this.props.active + 3; i++) {
      let index = i;
      if (i < 0) {
        index = this.props.items.length + i;
      } else if (i >= this.props.items.length) {
        index = i % this.props.items.length;
      }
      level = this.props.active - i;
      if (level >= 0) {
        items.push(<Item
          key={index}
          urlPath={this.props.items[index]} onClick={level > 1 ? this.props.moveLeft : this.props.moveRight}
          level={level}
        />);
      }
    }
    return items;
  }

  render () {
    return (
      <div className="noselect single-carousel-wrapper">
        <ReactCSSTransitionGroup
          transitionName={this.props.direction}>
          {this.generateItems()}
        </ReactCSSTransitionGroup>
      </div>
    );
  }
}

class Item extends React.Component {
  render () {
    return (
      <div className={'item level' + this.props.level} onClick={this.props.onClick}>
        <img src={this.props.urlPath} />
      </div>
    );
  }
}

export default Slider;
