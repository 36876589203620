const testContent = {
  stage0: {
    question: '1. Что ты выберешь?',
    answers: ['Шахматы', 'Бег', 'Бокс'],
  },
  stage1: {
    question: '2. Какую суперсилу бы хотел?',
    answers: ['Скорость', 'Чтение мыслей', 'Физическую силу'],
  },
  stage2: {
    question: '3. Куда бы ты поехал?',
    answers: ['Европа', 'Азия', 'Австралия'],
  },
  stage3: {
    question: '4. Какого питомца ты бы хотел?',
    answers: ['Собака', 'Кошка', 'Сокол'],
  },
  stage4: {
    question: '5. Твой любимый герой?',
    answers: ['Алладин', 'граф д’Артанья́н', 'Король Артур'],
  },
  'Алладин': {
    header: 'Глефа',
    description: 'Глефа относится к древковому типу колюще-рубящего холодного оружия. В средние века ее использовали пехотинцы на территории европейских стран для ведения ближнего боя. Сегодня традиционные глефы можно встретить на вооружении швейцарской гвардии, которая состоит на службе у Ватикана.',
    imgLink: 'https://storage.yandexcloud.net/arsenal-images/weapons-test%2FW10.png',
  },
  'граф д’Артанья́н': {
    header: 'Рапира',
    description: 'Рапира -  тонкий и острый меч, используемый  для колющих ударов, распространенный в Европе в 16-17 веках. Такое оружие старались делать легким, красивым и утонченным. Оно мало подходило для реального боя, и было скорее атрибутом знати.',
    imgLink: 'https://storage.yandexcloud.net/arsenal-images/weapons-test%2FW4.png',
  },
  'Король Артур': {
    header: 'Двуручный меч',
    description: 'Двуручный меч - это  массивное обоюдоострое оружие, длинной от полутора метров.Двуручные мечи употреблялись небольшим числом опытных воинов, рост и сила которых заметно превышали средний уровень.',
    imgLink: 'https://storage.yandexcloud.net/arsenal-images/weapons-test%2FW6.png',
  },
};

export default testContent;
