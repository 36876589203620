import * as React from 'react';
import './testStyles.css';

import testContent from './testContent';
import StageTemplate from './components/stageTemplate';
import TestResult from './components/TestResult';

type TestContentType = {
    stage0: {
        question: string,
        answers: string[],
    },
    stage1: {
        question: string,
        answers: string[],
    },
    stage2: {
        question: string,
        answers: string[],
    },
    stage3: {
        question: string,
        answers: string[],
    }
}

class WeaponTest extends React.Component {
    testContent: TestContentType

    state = {
      stage: 0,
      answr: null,
    }

    _changeStage = (answr) => {
      this.setState({ stage: this.state.stage + 1, answr });
    }

    render () {
      if (this.state.stage >= 5) {
        return <TestResult
          {...testContent[this.state.answr]}
          closeModal={this.props.closeModal}
        />;
      }

      return (
        <StageTemplate
          stage={this.state.stage}
          question={testContent[`stage${this.state.stage}`].question}
          answers={testContent[`stage${this.state.stage}`].answers}
          setNextStep={this._changeStage}
          closeModal={this.props.closeModal}
        />
      );
    }
}

export default WeaponTest;
