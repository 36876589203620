import * as React from 'react';
import './styles.css';
import Plx from 'react-plx';
import text from '../../text';
import ExpandCaption from '../../../../components/ExpandCaption';
const huntImg = 'https://storage.yandexcloud.net/arsenal-images/huntBG.png';
const huntHPartImg = 'https://storage.yandexcloud.net/arsenal-images/hunterAndDogs.png';

const HuntEnd = () => {
  return (
    <div className="hunt-end-wrapper" >
      <Plx parallaxData={parallaxData} className="dogman-img-wrapper">
        <img src={huntHPartImg} alt="Крестьяне и охотники" />
      </Plx>
      <div className="hunt-end-big-img-wrapper" >
        <img src={huntImg} alt="Псарь" />
      </div>
      <div className="hunt-end-text-wrapper">
        <span>{text.huntEnd}</span>
        <ExpandCaption caption={text.huntCaption} />
      </div>
    </div>
  );
};

const parallaxData = [
  {
    start: 'self',
    duration: '90vh',
    properties: [
      {
        startValue: 60,
        endValue: 5,
        property: 'translateY',
        unit: '%',
      },
      {
        startValue: 90,
        endValue: 0,
        property: 'translateX',
        unit: '%',
      },
    ],
  },
];

export default HuntEnd;
