import * as React from 'react';

type Props = {
    stageNumber: number,
    question: string,
    answers: string[],
    setNextStep: () => void
}

const stageTemplate = (props: Props) => {
  return (
    <div className="stage-template-wrapper">
      <span className="close-button red" onClick={props.closeModal}>X</span>
      <div className="test-block-wrapper">
        <h2>{props.question}</h2>
        <div className="stage-template-answers-wrapper">
          {props.answers.map((curr, i) =>
            <div
              key={i}
              onClick={() => props.setNextStep(curr)}>
              <span className="test-circle" />
              <span>{curr}</span>
            </div>
          )}
        </div>
      </div>
      <div className="stage-counter-wrapper">
        {[...Array(props.stage + 1).keys()].map(() => <div className="stage-counter" />) }
      </div>
    </div>
  );
};

export default stageTemplate;
