import * as React from 'react';
import './testInvitationStyles.css';

const TestInvitation = (props) => {
  return (
    <div className="test-invitation-wrapper">
      <div className="test-start-img">
        <img
          src="https://storage.yandexcloud.net/arsenal-images/weapons-test%2FW11.png"
        />
      </div>
      <div onClick={props.openModal} className="button">
        <span>МЕЧ ТЕБЕ В РУКУ</span>
      </div>
      <div className="test-end-img">
        <img
          src="https://storage.yandexcloud.net/arsenal-images/weapons-test%2FW11.png"
        />
      </div>
      <div className="rose-white-background-second" />
    </div>
  );
};

export default TestInvitation;
