import React, { Component } from 'react';
import AppContainer from './AppContainer';
import Loader from './containers/Loader';
class App extends Component {
  state = {
    loadEnded: false,
    isPreUnmount: false,
  }

  componentWillMount () {
    window.onload = () => {
      this.setState({ isPreUnmount: true });

      setTimeout(() => {
        this.setState({ loadEnded: true });
      }, 500);
    };
  }

  render () {
    const { state } = this;
    return (
      <div style={!state.loadEnded ? { height: '100vh', overflowY: 'hidden' } : {}}>
        <Loader shouldRender={!state.loadEnded} isPreUnmount={state.isPreUnmount} />
        <AppContainer isLoad={state.loadEnded} />
      </div>
    );
  }
}

export default App;
