import React, { Component } from 'react';
import Hunt from './containers/HuntContainer/Hunt';
import Monbizu from './containers/MonbizuContainer/Monbizu';
import Start from './containers/Start/Start';
import Arsenal from './containers/Arsenal/Arsenal';
import Menu from './containers/Menu/Menu';
import Weapon from './containers/Weapon/Weapon';
import WeaponTest from './containers/WeaponTest';
import Carusel from './containers/Carusel/Carusel';
import Restoration from './containers/Restoration/Restoration';
import Location from './containers/Location/Location';
import VideoArmour from './containers/VideoArmour';
import Plan from './containers/Plan';
import { YMaps } from 'react-yandex-maps';

class AppContainer extends Component {
  render () {
    return (
      <YMaps>
        <div className="app-wrapper" >
          <Menu />
          <Start isLoad={this.props.isLoad} />
          <Monbizu />
          <Hunt />
          <Arsenal />
          <VideoArmour />
          <Weapon />
          <WeaponTest />
          <Carusel />
          <Restoration />
          <Plan />
          <div className="loaction-non-modal-outer-wrapper container" id="location-end">
            <h3>Как попасть</h3>
            <Location nonModal />
          </div>
        </div>
      </YMaps>
    );
  }
}

export default AppContainer;
