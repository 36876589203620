import * as React from 'react';
import './styles.css';
import Plx from 'react-plx';
import Modal from 'react-modal';
import Location from '../Location/Location';

const arsenalMainFront = 'https://storage.yandexcloud.net/arsenal-images/arsenal-main-front.png';
const arsenalMainBG = 'https://storage.yandexcloud.net/arsenal-images/arsenal-main-bg.png';
const armorPattern = 'https://storage.yandexcloud.net/arsenal-images/armor-pattern.png';

class Start extends React.Component {
  state = {
    isOpenModal: false,
  }

  toggleOpen = () => {
    if (window.innerHeight > window.innerWidth) {
      document.getElementById('location-end').scrollIntoView();
      return;
    }
    this.setState({ isOpenModal: !this.state.isOpenModal });
  }

  render () {
    return (
      <div className="start-container" >
        <span className="tip start-arrow-down">⥥</span>
        <div className="line" />
        <div className="armor-pattern">
          <img src={armorPattern} alt="Броня рыцаря из коллекции царскго села" />
        </div>
        <div className="header-and-arsenal-wrapper">
          <Plx parallaxData={this.getPrlxData({ startValue: 0, endValue: 30 })}
            className="start-text-wrapper">
            <h1>Арсенал</h1>
            <div className="start-text-description">
              <div>
                <span>История "Замка рыцаря" </span>
                <span>первого Императорского музея оружия</span>
              </div>
              <div>
                <span>Царское село</span>
                <span>Александровский Парк</span>
              </div>
            </div>
          </Plx>
          <div className="start-img-container">
            <img src={arsenalMainFront} className={this.props.isLoad ? 'start-img-front' : ''}
              alt="Арсенал, Царское Село" />
            <Plx parallaxData={this.getPrlxData({ startValue: 0, endValue: 2 })} className="start-img-back">
              <img src={arsenalMainBG} className="start-img" alt="Лес рядом с Арсеналом" />
            </Plx>
          </div>
        </div>
        <div className="start-button-wrapper">
          <div className="start-button" onClick={this.toggleOpen}>
            <span>
                Посетить
            </span>
          </div>
        </div>
        <Modal
          isOpen={this.state.isOpenModal}
          onRequestClose={this.toggleOpen}
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
          style={
            {
              overlay: { zIndex: 3000 },
              content: {
                background: '#ededed',
                backgroundImage: 'url(https://storage.yandexcloud.net/arsenal-images/red-dust.png)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                boxShadow: '0 0 20px rgba(128, 76, 52, 0.5)' },
            }}
        >
          <Location closeWindow={this.toggleOpen} />
        </Modal>
      </div>
    );
  }

  getPrlxData = ({ startValue, endValue }) => (
    [
      {
        start: 'self',
        duration: '50vh',
        properties: [
          {
            startValue,
            endValue,
            property: 'translateY',
            unit: '%',
          },
        ],
      },
    ]
  );
}

export default Start;
