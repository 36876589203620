import React from 'react';
import FloorTemplate from './floorTemplate';
import { descriptions } from './text';

class PlanContainer extends React.Component {
    floors = ['https://storage.yandexcloud.net/arsenal-images/plan/floor_0.png',
      'https://storage.yandexcloud.net/arsenal-images/plan/floor_1.png',
      'https://storage.yandexcloud.net/arsenal-images/plan/floor_2.png']

    render () {
      return (
        <div className="container plan-container ">
          <FloorTemplate
            header="2 этаж"
            imgPath={this.floors[2]}
            descriptions={descriptions.floor2}
          />
          <FloorTemplate
            header="1 этаж"
            imgPath={this.floors[1]}
            descriptions={descriptions.floor1}
          />
          <FloorTemplate
            header="0 этаж"
            imgPath={this.floors[0]}
            descriptions={descriptions.floor0}
          />
        </div>
      );
    }
}

export default PlanContainer;
