import * as React from 'react';
import '../VideoArmourStyles.css';

const armourImgPath = ['https://storage.yandexcloud.net/arsenal-images/wear_armour/wear_1.png', 'https://storage.yandexcloud.net/arsenal-images/wear_armour/wear_2.png', 'https://storage.yandexcloud.net/arsenal-images/wear_armour/wear_3.png'];
const backgroundImg = 'https://storage.yandexcloud.net/arsenal-images/wear_armour/bg_union_min.png';

type Props = {
    videoElement: HTMLVideoElement,
    videoWidth: Number,
    videoHeight: Number,
    renderScreenShot: (Object) => void;
}

class Canvas extends React.Component<Props> {
  canvasContext = null
  canvasRef = React.createRef();
  timeOutld = null
  armourImg = null
  videoWidthCenter= this.props.videoWidth / 2
  videoHeightCenter= this.props.videoHeight / 2

  state = {
    screenShoot: null,
  }

  componentDidMount () {
    this.backgroundImg = new Image();
    this.backgroundImg.crossOrigin = 'Anonymous';
    this.backgroundImg.src = backgroundImg;

    this.armourImg = new Image();
    this.armourImg.crossOrigin = 'Anonymous';
    this.armourImg.src = armourImgPath[this.props.activeImgNumber];

    this.canvasContext = this.canvasRef.current.getContext('2d');
    this.updateCanvas();
  }

  componentDidUpdate () {
    this.armourImg.src = armourImgPath[this.props.activeImgNumber];
    this.updateCanvas();
  }

  updateCanvas = () => {
    if (!this.canvasContext || !this.props.videoElement) return;
    this.renderVideo();
    this.canvasContext.drawImage(this.backgroundImg, 0, 0, this.props.videoWidth, this.props.videoHeight);
    this.canvasContext.drawImage(this.armourImg, ...this.getImgResolution());
    this.timeOutld = setTimeout(this.updateCanvas, 1000 / 30);
  }

  getImgResolution = () => {
    const aspect = this.armourImg.width / this.armourImg.height;
    const newImgWidth = this.props.videoHeight * aspect;
    let margin = this.videoWidthCenter - (this.armourImg.width / 3);

    if (this.props.videoHeight > this.props.videoWidth) {
      margin = this.videoWidthCenter - (this.armourImg.width / 5);
    }

    return [
      0,
      0,
      this.armourImg.width,
      this.armourImg.height,
      margin,
      0,
      newImgWidth,
      this.props.videoHeight,
    ];
  }

  renderVideo () {
    this.canvasContext.drawImage(this.props.videoElement,
      0, 0,
      this.props.videoWidth, this.props.videoHeight);
  }

   getScreenShot = () => {
     const imgData = this.canvasRef.current.toDataURL('image/png');
     console.log('capture img');
     this.props.renderScreenShot(imgData);
   }

   componentWillUnmount () {
     clearTimeout(this.timeOutld);
   }

   render () {
     return (
       <div style={{ position: 'relative' }}>
         <canvas ref={this.canvasRef} width={this.props.videoWidth} height={this.props.videoHeight} />
         <div className="screen-shot-buttons-wrapper">

           <div className="create-screen-shot-button"
             onClick={this.getScreenShot}>
             <img src={this.props.screenShotIconPath} />
           </div>
         </div>

       </div>
     );
   }
}

export default Canvas;
