import * as React from 'react';
import './styles.css';

type Props = {
    header: string,
    years: string,
}

const HuntHeader = (props: Props) => {
  return (
    <div className="hunt-header-wrapper" >
      <div className="hunt-header-text-wrapper">
        <h1>{props.header.toUpperCase()}</h1>
        <h3>{props.years[0]} - {props.years[1]}</h3>
      </div>
      <div className="hunt-header-blue-color-block" />
      <div className="hunt-header-yellow-color-block" />
    </div>
  );
};

export default HuntHeader;
