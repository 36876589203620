import * as React from 'react';
import { text, text2 } from './text';
import './styles.css';

import Slider from './components/Slider';

const Restoration = () => {
  return (
    <div className="restoration-container container" id="restoration">
      <h3>Реставрация</h3>
      <span>
        {text[0]}
      </span>
      <br />
      <span>
        {text2[0]}
      </span>
      <br />
      <span>
        {text2[1]}
      </span>
      <Slider
      />
    </div>
  );
};

export default Restoration;
