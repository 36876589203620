/* eslint-disable max-len */

export const text = [`31 января 1834 года Николай I, находясь в Царском Селе, повелел, чтобы Монбижу "впредь называть Арсеналом, предав первое имя забвению".`,
  `Николай I решил разместить в павильоне свою коллекцию оружия, превратив Арсенал в музей.`];

export const text2 = {
  header: 'Оружейная Рыцаря',
  body: `Прихожую, украшали одетые в броню рыцари, создававшие иллюзию караульной стражи. В обширном зале "Огнестрельного оружия" по сторонам стояли две фигуры черногорцев, одетых в национальные костюмы, а посреди стояли винтовки и пирамида снарядов.
На втором этаже замка главным помещением был "Рыцарский зал", представлявший собой правильный восьмиугольник. В полном вооружении кругом зала стояли на пьедесталах семь конных рыцарей в полном вооружении.`,
  caption: 'Надежда Алексеевна Ионина, "100 Великих сокровищ"',
  captionExpand: `В обширном зале "Огнестрельного оружия" по сторонам стояли две фигуры черногорцев, одетых в национальные костюмы, а посреди стояли винтовки и пирамида снарядов, изготовленных на Тульском оружейном заводе. 
  В окнах "Комнаты Императрицы" светились швейцарские и немецкие витражи XVI-XVII веков, стояла кровать под балдахином с занавесками и стеганым одеялом из китайского атласа, расшитого цветами и фигурами. Кровать с обеих сторон "охраняли" два максимилиановских доспеха. 
  На втором этаже замка главным помещением был "Рыцарский зал", представлявший собой правильный восьмиугольник с высокими стрельчатыми потолками и высокими полукружиями окон. В полном вооружении кругом зала стояли на пьедесталах семь конных рыцарей в полном вооружении.`,
};
