import React from 'react';
import './planStyles.css';

const floorTemplate = ({ imgPath, descriptions, header }:
     {header: string, imgPath: string, descriptions: {number: number, description: string}[]}) => {
  return (
    <div className="floor-template-wrapper">
      <h2>{header}</h2>
      <div className="floor-img-description-wrapper">
        <div className="floor-img-wrapper">
          <img src={imgPath} />
        </div>
        <div className="floor-descriptions-wrapper">
          {descriptions.map(
            curr => {
              return (
                <span key={curr.number}>{`${curr.number} - ${curr.description}`}</span>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default floorTemplate;
