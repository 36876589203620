import * as React from 'react';
import './styles.css';
import WeaponItem from './components/WeaponItem';
const armor = 'https://storage.yandexcloud.net/arsenal-images/armor.png';

class WeaponCircle extends React.Component {
    state = {
      description: null,
    }

    renderMobileDescription = (description) => {
      if (description === this.state.description) {
        this.setState({ description: null });
        return;
      }
      this.setState({ description });
    }

    render () {
      return (
        <div>
          <div className="weapon-mobile-description">
            <span>{this.state.description}</span>
          </div>
          <div className="weapon-circle-armor" />
          <div className="weapon-circle">
            <span className="tips weapons-tip" />
            <div className="weapon-circle-bg" />
            <img className="weapon-tool-img" src="https://storage.yandexcloud.net/arsenal-images/tool-icon.png" />
            <img src={armor} className="weapon-armor-img" alt="Рейтарский доспех. Нюрнберг. Германия XVII в." />
            {this.weapons.map((curr, i) => (
              <WeaponItem weapon={curr} index={i} key={i} onClick={this.renderMobileDescription} />
            ))}
          </div>
        </div>
      );
    }

      weapons = [
        { link: 'https://storage.yandexcloud.net/arsenal-images/weapons-test%2FW1.png',
          description: 'Кинжал. Италия . Сер. XVI в.',
        },
        { link: 'https://storage.yandexcloud.net/arsenal-images/weapons-test%2FW2.png',
          description: 'Куза. Франция. нач XVI в.',
        },
        { link: 'https://storage.yandexcloud.net/arsenal-images/weapons-test%2FW3.png',
          description: 'Полуторный меч. Италия. Нач. XVI в.',
        },
        { link: 'https://storage.yandexcloud.net/arsenal-images/weapons-test%2FW4.png',
          description: 'Рапира. Брешия. Италия. 1650-е',
        },
        { link: 'https://storage.yandexcloud.net/arsenal-images/weapons-test%2FW5.png',
          description: 'Кончар Германия XIV в.',
        },
        { link: 'https://storage.yandexcloud.net/arsenal-images/weapons-test%2FW6.png',
          description: 'Двуручный меч Германия. сер XVI в.',
        },
        { link: 'https://storage.yandexcloud.net/arsenal-images/weapons-test%2FW7.png',
          description: 'Шпага. Франция кон. XVI в.',
        },
        { link: 'https://storage.yandexcloud.net/arsenal-images/weapons-test%2FW8.png',
          description: 'Двуручный меч-фламберг. Германия. 1590-е.',
        },
        { link: 'https://storage.yandexcloud.net/arsenal-images/weapons-test%2FW9.png',
          description: 'Сабля. Германия. XVI в.',
        },
        { link: 'https://storage.yandexcloud.net/arsenal-images/weapons-test%2FW10.png',
          description: 'Глефа. Венеция, Италия Нач. XVII в.',
        },
        { link: 'https://storage.yandexcloud.net/arsenal-images/weapons-test%2FW11.png',
          description: 'Кинжал-дага. Германия. XVI в.',
        },
      ]
}

export default WeaponCircle
;
