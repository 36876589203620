import * as React from 'react';
import './styles.css';
import Plx from 'react-plx';
import text from '../../text';
const huntHorsesImg = 'https://storage.yandexcloud.net/arsenal-images/hunt-horses.png';

const HuntHorses = () => {
  return (
    <div className="hunt-horses-outter-wrapper" >
      <div className="hunt-horses-content">
        <div className="hunt-horses-text-wrapper">
          <span>{text.huntStartSecond}</span>
        </div>
        <Plx className="hunt-horses-img" parallaxData={parallaxData}>
          <img src={huntHorsesImg} alt="Всадники с горнами" />
        </Plx>
      </div>
    </div>
  );
};

const parallaxData = [
  {
    start: 'self',
    duration: '90vh',
    properties: [
      {
        startValue: 70,
        endValue: 10,
        property: 'translateX',
        unit: '%',
      },
      {
        startValue: 70,
        endValue: 0,
        property: 'translateY',
        unit: '%',
      },
    ],
  },
];

export default HuntHorses;
