import * as React from 'react';
import './styles.css';
import Plx from 'react-plx';
import text from '../../text';
const huntHornsImg = 'https://storage.yandexcloud.net/arsenal-images/hunt-horns.png';

class HuntHorns extends React.Component {
  state = {
    isImgInVisible: false,
  }
  _handleImgVisible = () => {
    this.setState({ isImgInVisible: true });
  }

  render () {
    return (
      <div style={{ position: 'relative' }}>
        <div className="hunt-horns-wrapper">
          <Plx parallaxData={this.parallaxData}>
            <img src={huntHornsImg} className="hunt-horns-img" alt="Всадники" />
          </Plx>
          <div className="hunt-horns-text-wrapper">
            <span>{text.huntStart}</span>
          </div>
        </div>
      </div>

    );
  }

  parallaxData = [
    {
      start: 'self',
      duration: '90vh',
      properties: [
        {
          startValue: -50,
          endValue: 0,
          property: 'translateX',
          unit: '%',
        },
        {
          startValue: -10,
          endValue: -10,
          property: 'translate Y',
          unit: '%',
        },
      ],
    },
  ];

  parallaxText = [
    {
      start: 'self',
      duration: '150vh',
      properties: [
        {
          startValue: 0,
          endValue: 150,
          property: 'translateY',
          unit: '%',
        },
      ],
    },
  ];
}

export default HuntHorns;
