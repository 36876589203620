export const descriptions = {
  floor0: [
    {
      number: 12,
      description: 'Гардероб',
    },
    {
      number: 13,
      description: 'Сувенирный магазин',
    },
    {
      number: 14,
      description: 'Касса',
    },
    {
      number: 15,
      description: 'Стойка администратора',
    },
    {
      number: 16,
      description: 'Демонстрационный зал',
    },
    {
      number: 17,
      description: 'Туалет',
    },
  ],
  floor1: [
    {
      number: 6,
      description: 'Вестибюль',
    },
    {
      number: 7,
      description: 'Экспозиция «История музея»',
    },
    {
      number: 8,
      description: 'Экспозиция «Европейское средневековое вооружение»',
    },
    {
      number: 9,
      description: 'Мультимедийная экспозиция',
    },
    {
      number: 10,
      description: 'Экспозиция «Неоготика в декоративно-прикладном искусстве»',
    },
    {
      number: 11,
      description: 'Албанская комната',
    },
  ],
  floor2: [
    {
      number: 1,
      description: 'Зал рыцарей. Экспозиция «Оружие Турции, Персии и Кавказа»',
    },
    {
      number: 2,
      description: 'Экспозиция «Оружие Средней Азии, Дальнего Востока и Африки, Конская амуниция»',
    },
    {
      number: 3,
      description: 'Экспозиция «Восточное огнестрельное оружие»',
    },
    {
      number: 4,
      description: 'Электронная библиотека ',
    },
    {
      number: 5,
      description: 'Лифт',
    },
  ],
};
