import * as React from 'react';
import SectionHeader from '../../components/SectionHeader';
import MonbizuSplit from './components/MonbizuSplit';
import './styles.css';
import text from './text';

class Monbizu extends React.Component {
  render () {
    return (
      <div ref={this._getRef} className="monbizu-container container" id="monbizu">
        <SectionHeader header="Монбижу" years={[1747, 1754]} />
        <div className="monbizu-quote-wrapper">
          <div className="monbizu-quote">
            <span>{text.monbizuFirst}</span>
          </div>
          <br />
          <div className="monbizu-quote">
            <span>{text.monbizuSecond}</span>
          </div>
        </div>
        <MonbizuSplit />
      </div>
    );
  }
}

export default Monbizu;
