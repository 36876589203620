import * as React from 'react';

type Props = {
    description: string,
    header: string,
    text: string[],
    imgLink: string,
}

const testResult = (props: Props) => {
  return (
    <div className="stage-result-wrapper">
      <span className="close-button red" onClick={props.closeModal}>X</span>
      <div className="test-result-wrapper">
        <div className="result-text-wrapper test-block-wrapper ">
          <h2>{props.header}</h2>
          <span>{props.description}</span>
        </div>
        <div className="test-img-wrapper">
          <img src={props.imgLink} />
        </div>
      </div>
      <div className="stage-counter-wrapper">
        {[...Array(5).keys()].map(() => <div className="stage-counter" />) }
      </div>
    </div>
  );
};

export default testResult;
