import React from 'react';
import './styles.css';
import logo from '../../img/logo.png';

type Props = {
    shouldRender: boolean,
    isPreUnmount: boolean,
}
const Loader = (props: Props) => {
  if (!props.shouldRender) return null;
  return (
    <div className="loader-wrapper ">
      <div className={`loader-img-wrapper ${props.isPreUnmount ? 'img-fade-out' : ''}`}>
        <img src={logo} alt="Герб Царского Села" />
      </div>
    </div>
  );
};

export default Loader;
