import * as React from 'react';
import { text } from './text';
import './styles.css';

const AfterMonbizu = () => {
  return (
    <div className="after-monbizu-wrapper">
      <div className="after-monbizu-line" />
      <span className="numbers">1800</span>
      <span>
        {text[0]}
      </span>
      <br />
      <span className="numbers">1801</span>
      <span>
        {text[1]}
      </span>
    </div>
  );
};

export default AfterMonbizu;
