import * as React from 'react';
import HuntHeader from './components/HuntHeader';
import HuntHorns from './components/HuntHorns';
import HuntHorses from './components/HuntHorses';
import HuntHorsesSecond from './components/HuntHorsesSecond';
import HuntEnd from './components/HuntEnd';
import AfterMonbizu from './components/AfterMonbizu/AfterMonbizu';
import './styles.css';

class Hunt extends React.Component {
  render () {
    const { props } = this;
    return (
      <div className="hunt-container container" id="hunt">
        <HuntHeader header="Императорская охота" years={[1718, 1753]} />
        <HuntHorns isContainerExists={props.isContainerExists} />
        <HuntHorses />
        <HuntHorsesSecond />
        <HuntEnd />
        <AfterMonbizu />
      </div>
    );
  }
}

export default Hunt;
