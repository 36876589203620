import React from 'react';
import Modal from 'react-modal';
import VideoArmourContainer from './VideoArmourContainer';
import StartSection from './components/startSection';

class ModalContainer extends React.Component {
    state = {
      isOpen: false,
      height: window.innerHeight / 1.5,
      width: window.innerWidth / 1.5,
    }

    openModal = () => {
      this.setState({ isOpen: true });
    }

    closeModal = () => {
      this.setState({ isOpen: false });
    }

    render () {
      return (
        <div className="videoModalContainer-wrapper">
          <StartSection openModal={this.openModal} />
          <Modal
            isOpen={this.state.isOpen}
            onRequestClose={this.closeModal}
            shouldCloseOnEsc
            shouldCloseOnOverlayClick
            style={{ overlay: {
              zIndex: 3000,
            },
            content: {
              height: this.state.height + 10,
              width: this.state.width + 10,
              margin: 'auto',
            },
            }}
          >
            <span className="close-button" onClick={this.closeModal}>X</span>
            <VideoArmourContainer
              height={this.state.height}
              width={this.state.width}
            />
          </Modal>
        </div>
      );
    }
}

export default ModalContainer;
