import * as React from 'react';
import './styles.css';

type Props = {
  onClick: (string) => void;
  index: number,
    weapon: { link: string, description: string }
}

class WeaponItem extends React.Component<Props> {
    state ={
      isHover: false,
    }

    handleHover = () => {
      this.setState({ isHover: !this.state.isHover });
    }

    getDescriptionStyles = () => {
      if (this.props.index === 0) return { left: '-10vw', marginTop: '5vw' };
      return { left: this.props.index > 5 ? '5vw' : 'unset' };
    }

    render () {
      const { weapon } = this.props;
      return (
        <div
          onMouseOver={() => this.handleHover()}
          onMouseOut={() => this.handleHover()}
          onClick={() => this.props.onClick(weapon.description)}
          key={this.props.index}
          className="weapon-item"
        >
          <div className="weapon-item-img-container">
            <img className="weapon-item-img" src={weapon.link} alt={weapon.description} />
          </div>
          {this.state.isHover
            ? <span className="weapon-description" style={this.getDescriptionStyles()}>{weapon.description} </span> : null}
        </div>
      );
    }
}

export default WeaponItem;
