import * as React from 'react';
import CaruselHeader from './components/CaruselHeader';
import './styles.css';
import text from './text';
import Plx from 'react-plx';
import Modal from 'react-modal';
import ExpandCaption from '../../components/ExpandCaption';
const img1 = 'https://storage.yandexcloud.net/arsenal-images/firstCarusel.png';
const img2 = 'https://storage.yandexcloud.net/arsenal-images/secondCarusel.png';
const carusel = 'https://storage.yandexcloud.net/arsenal-images/carusel-resize.jpg';

class Carusel extends React.Component {
  state = {
    isModalOpen: false,
  }

  handleImgClick = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }

  render () {
    return (
      <div className="carusel-container container" id="carusel">
        <CaruselHeader />
        <div className="carusel-text-wrapper text-container">
          <div styles={{ position: 'relative' }}>
            <div className="carusel-line" />
            <div className="carusel-section">
              <Plx parallaxData={parallaxData} className="carusel-img-wrapper" >
                <img src={img1} alt="Дети играют во время царскосельской карусели" />
              </Plx>
              <div className="carusel-text-block">
                <span>{text.header}</span>
                <span>{text.caruselDescription}</span>
              </div>
            </div>
            <div className="carusel-mobile-full-img">
              <img src={carusel} />
            </div>
            <div className="carusel-section">
              <Plx parallaxData={parallaxData} className="carusel-img-wrapper" >
                <img src={img2} alt="Николай I во время царскосельской карусели" />
              </Plx>
              <div className="carusel-text-block">
                <span>{text.caruselStoryOne}</span>
                <span>{text.caruselStoryTwo}</span>
              </div>
            </div>
          </div>
          <ExpandCaption caption={text.storyCaption} additionalText={text.additionalText} />
        </div>
        <div
          className="carusel-full-img"
          onClick={this.handleImgClick}>
          <img className="carusel-tool-img" src="https://storage.yandexcloud.net/arsenal-images/tool-icon.png" />
          <img src={carusel} />
        </div>
        <Modal isOpen={this.state.isModalOpen} overlayClassName="carusel-modal-wrapper">
          <span className="close-button" onClick={() => { this.setState({ isModalOpen: false }); }}>X</span>
          <div style={{ height: '100%' }} className="carusel-modal-img" >
            <div className="carusel-modal-img-wrapper">
              <img src={carusel} />
            </div>
            <span className="caption carusel-img-caption">О.Верне Царскосельская карусель 1843г.</span>
          </div>
        </Modal>
      </div>
    );
  }
}

const parallaxData = [
  {
    start: 'self',
    duration: '40vh',
    properties: [
      {
        startValue: 20,
        endValue: 0,
        property: 'translateX',
        unit: '%',
      },
    ],
  },
];

export default Carusel;
