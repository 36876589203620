import React from 'react';
import Canvas from './components/canvas';

type State = {
  videoElement: React.ComponentType,
  videoStream: Object,
  videoError: null,
  renderScreenShot: (Object) => void,
}

class VideoArmour extends React.Component<{height: number, width: number}, State > {
    state = {
      videoElement: null,
      videoError: null,
      videoStream: null,
      activeImg: 1,
    }

    previewPaths = [
      'https://storage.yandexcloud.net/arsenal-images/wear_armour/preview_1.png',
      'https://storage.yandexcloud.net/arsenal-images/wear_armour/preview_2.png',
      'https://storage.yandexcloud.net/arsenal-images/wear_armour/preview_3.png',
    ]

    videoRef = React.createRef();

    componentDidMount () {
      const { width, height } = this.props;

      if (navigator && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        console.log('start recording video');
        navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user' },
          width: { exact: width },
          height: { exact: height },
          aspectRatio: {
            exact: width / height,
          },
        })
          .then((stream: Stream) => {
            console.log('read stream');
            if (this.videoRef && this.videoRef.current) {
              this.videoRef.current.srcObject = stream;
              this.setState({ videoElement: this.videoRef.current, videoStream: stream });
            }
          }).catch((err) => {
            console.error('error', err);
            this.setState({ videoError: err });
          });
      }
    }

    increaseNumber = () => {
      this.setState({
        activeImg: this.state.activeImg + 1,
      });
    }

    decreesNumber = () => {
      this.setState({
        activeImg: this.state.activeImg - 1,
      });
    }

    componentWillUnmount () {
      if (this.state.videoStream) {
        this.state.videoStream.getTracks()[0].stop(); // if only one media track
      }
    }

    render () {
      const { width, height } = this.props;

      if (this.state.videoError) {
        return <div className="camera-error">
          <h1>Пожалуйста, подключите камеру</h1>
        </div>;
      }
      return (
        <div style={{ position: 'relative' }}>
          <video ref={this.videoRef} autoPlay style={{
            display: 'none',
          }} height={height} width={width} crossOrigin="anonymous" />

          <Canvas
            videoElement={this.state.videoElement}
            videoHeight={height} videoWidth={width}
            renderScreenShot={this.props.renderScreenShot}
            screenShotIconPath={this.props.screenShotIconPath}
            activeImgNumber={this.state.activeImg}
          />
          {this.state.activeImg ? (
            <div className="wear_preview" style={{ left: 0 }} onClick={this.decreesNumber} >
              <img src={this.previewPaths[this.state.activeImg - 1]} />
            </div>
          ) : null}
          {this.state.activeImg <= 1 ? (
            <div className="wear_preview" style={{ right: 0 }} onClick={this.increaseNumber} >
              <img src={this.previewPaths[this.state.activeImg + 1]} />
            </div>) : null}
        </div>
      );
    }
}

export default VideoArmour;
