import React, { Component } from 'react';
import Slider from './Slider';

class SliderContainer extends Component {
  state = {
    active: 0,
    direction: '',

  }

  moveLeft = () => {
    let newActive = this.state.active;
    newActive--;
    this.setState({
      active: newActive < 0 ? 5 - 1 : newActive,
      direction: 'left',
    });
  }

  moveRight = () => {
    this.setState({
      active: (this.state.active + 1) % 5,
      direction: 'right',
    });
  }

  render () {
    return (
      <div className="double-slider-container">
        <div className="double-slider-wrapper">
          <span className="arrow arrow-left" onClick={this.moveLeft}>{'<'}</span>
          <div className="img-sliders-wrapper">
            <Slider
              items={oldArsenalImgs}
              active={this.state.active}
              moveRight={this.moveRight}
              moveLeft={this.moveLeft} />
            <Slider
              items={newArsenalImgs}
              active={this.state.active}
              moveRight={this.moveRight}
              moveLeft={this.moveLeft} />
          </div>
          <span className="arrow arrow-right" onClick={this.moveRight}>{'>'}</span>
        </div>
        <div className="slider-counter-wrapper">
          {[...Array(5).keys()].map((curr, i) => {
            return <div
              key={i}
              className={`slider-counter ${i === this.state.active ? 'active-slider-counter' : ''}`} />;
          })}
        </div>
      </div>
    );
  }
}

const newArsenalImgs = [
  'https://storage.yandexcloud.net/arsenal-images/newArsenal/0.jpg',
  'https://storage.yandexcloud.net/arsenal-images/newArsenal/1.jpg',
  'https://storage.yandexcloud.net/arsenal-images/newArsenal/2.jpg',
  'https://storage.yandexcloud.net/arsenal-images/newArsenal/3.jpg',
  'https://storage.yandexcloud.net/arsenal-images/newArsenal/4.jpg',
];

const oldArsenalImgs = [
  'https://storage.yandexcloud.net/arsenal-images/oldArsenal/0.jpg',
  'https://storage.yandexcloud.net/arsenal-images/oldArsenal/1.jpg',
  'https://storage.yandexcloud.net/arsenal-images/oldArsenal/2.jpg',
  'https://storage.yandexcloud.net/arsenal-images/oldArsenal/3.jpg',
  'https://storage.yandexcloud.net/arsenal-images/oldArsenal/4.jpg',
];

export default SliderContainer;
