import * as React from 'react';
import { Map, Placemark, ZoomControl } from 'react-yandex-maps';
import { text } from './text';
import './styles.css';

const Location = (props) => (
  <div style={{ width: '100%',
    height: '100%' }}>
    { props.nonModal ? null : <span onClick={() => props.closeWindow()} className="close-button">X</span> }
    <div className={props.nonModal ? 'location-container-non-nodal' : 'location-container'}>
      <Map defaultState={{ center: [59.721774, 30.377519], zoom: 16 }} className="location-map-wrapper" >
        <Placemark geometry={[59.721774, 30.377519]} />
        <ZoomControl
          options={{
            size: 'small',
            zoomDuration: 1000,
          }}
        />
      </Map>
      <div className="location-text-wrapper">
        <div className="location-exposition">
          <span >
            {text[0]}
          </span>
          <br />
          <span >
            {text[1]}
          </span>
        </div>
        <div className="location-contact-info">
          <h5>
      РЕЖИМ РАБОТЫ:
          </h5>
          <span >
        10:00 – 18:00 (касса и вход посетителей до 17:00)
            <br />
        Выходные дни: понедельник и последний вторник каждого месяца
          </span>
          <h5>
      СТОИМОСТЬ БИЛЕТОВ:
          </h5>
          <span >
        Взрослые – 250 руб.
          </span>
          <span >
        Льготные категории – 100 руб.
          </span>
        </div>
      </div>
    </div>
  </div>
);

export default Location;
