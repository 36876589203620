import * as React from 'react';
import './styles.css';
import Header from './components/Header';
import History from './components/History';
const nikolay = 'https://storage.yandexcloud.net/arsenal-images/nikolay-on-horse-crop.png';

class Arsenal extends React.Component {
  render () {
    return (
      <div className="arsenal-container container" id="arsenal" >
        <div className="arsenal-img-wrapper">
          <img src={nikolay} className="nikolay-img" alt="Николай 1. Император" />
        </div>
        <Header header="Арсенал" years={`1825 - ${new Date().getFullYear()}`} />
        <History />
      </div>
    );
  }
}

export default Arsenal;
