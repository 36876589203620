import * as React from 'react';
import './styles.css';
import MenuItem from './components/MenuItem';

class Menu extends React.Component {
  state = {
    currentPosistion: 0,
  }

  componentDidMount () {
    window.addEventListener('scroll', this._handleScroll);
  }

  _handleScroll = () => {
    this.setState({ currentPosistion: window.pageYOffset });
  }

  _getHeight = ({ element, isLastElement }) => {
    const elem = document.getElementById(element);
    let rect;
    if (elem) {
      rect = elem.getBoundingClientRect();
      if (rect.y >= 0) return 0.1;

      let elemHight = rect.height;
      if (isLastElement) {
        elemHight = rect.height - window.innerHeight;
      }
      const reuslt = ((rect.y * -1) / (elemHight / 100));
      return reuslt > 100 ? 100 : reuslt;
    }
  }

  render () {
    return (
      <nav className="menu-outter-wrapper">
        <ul className="menu-wrapper">
          <MenuItem
            header="Монбижу"
            link="monbizu"
            progerssInPerc={this._getHeight({ element: 'monbizu' })} />
          <MenuItem
            header="Охота"
            link="hunt"
            progerssInPerc={this._getHeight({ element: 'hunt' })} />
          <MenuItem
            header="Арсенал"
            link="arsenal"
            progerssInPerc={this._getHeight({ element: 'arsenal' })}
          />
          <MenuItem
            header="Оружие"
            link="weapons"
            progerssInPerc={this._getHeight({ element: 'weapons' })}
          />
          <MenuItem
            header="Карусель"
            link="carusel"
            progerssInPerc={this._getHeight({ element: 'carusel', isLastElement: true })}
          />
        </ul>
      </nav>
    );
  }
}

export default Menu;
