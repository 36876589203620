import * as React from 'react';
import './styles.css';
import { text, text2 } from './text';
import ExpandCaption from '../../components/ExpandCaption';
import WeaponCircle from './WeaponCircle';

class Weapon extends React.Component {
  render () {
    return (
      <div id="weapons" className="weapons-wrapper container">
        <div className="weapon-main-text text-container">
          <span className="numbers">1834</span>
          <span>{text[0]}</span>
          <br />
          <span>{text[1]}</span>
        </div>
        <WeaponCircle />
        <div className="weapon-second-text text-container">
          <h1>{text2.header}</h1>
          <br />
          <span>{text2.body}</span>
          <div className="rose-white-background" />
          <br />
          <ExpandCaption caption={text2.caption} additionalText={text2.captionExpand} />
        </div>
      </div>
    );
  }
}

export default Weapon;
