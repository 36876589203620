import * as React from 'react';
import './styles.css';
import Plx from 'react-plx';
import text from '../text';

class MonbizuSplit extends React.Component {
    parallax = undefined
    render () {
      return (
        <div className="monbizu-split-container">
          <div className="monbizu-split-left-wrapper">
            <Plx parallaxData={parallaxLeftImg} className="monbizu-split-left" />
            <Plx parallaxData={parallaxLeftColor} className="monbizu-split-left-color" />
          </div>
          <Plx parallaxData={parallaxRight} className="monbizu-split-right" >
            <div className="monbizu-rastreli-quote">
              <span className="monbizu-rastreli-quote-header">Растрелли писал:</span>
              <span className="monbizu-rastreli-quote-body">
                {text.rastrellyQuote}
              </span>
            </div>
          </Plx>
        </div>
      );
    }
}

const parallaxRight = [
  {
    start: 'self',
    duration: '50vh',
    properties: [
      {
        startValue: -20,
        endValue: -40,
        property: 'translateY',
        unit: '%',
      },
    ],
  },
];

const parallaxLeftColor = [
  {
    start: 'self',
    duration: '30vh',
    properties: [
      {
        startValue: -10,
        endValue: 30,
        property: 'translateY',
        unit: '%',
      },
    ],
  },
];

const parallaxLeftImg = [
  {
    start: 'self',
    duration: '50vh',
    properties: [
      {
        startValue: -20,
        endValue: 20,
        property: 'translateY',
        unit: '%',
      },
    ],
  },
];

export default MonbizuSplit;
