import * as React from 'react';
import './styles.css';
import text from '../../text';

const History = () => {
  return (
    <div className="arsenal-history-wrapper container">
      <div className="history-part-one">
        <span> {text.arsenalOne} </span>
        <br />
        <span> {text.arsenalTwo} </span>
      </div>
      <div className="history-part-two">
        <div className="history-img-wrapper">
          <img src={arsenal} className="history-img" alt="Арсенал. Царское Село" />
        </div>
        <div className="history-two-text-wrapper">
          <span className="history-two-text">
            <span> {text.arsenalThree} </span>
          </span>
        </div>
      </div>
    </div>
  );
};

const arsenal = 'https://storage.yandexcloud.net/arsenal-images/arsenal.png';
export default History;
