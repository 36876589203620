import * as React from 'react';
import WeaponTest from './WeaponTest';
import TestInvitation from './components/TestInvitation';

import Modal from 'react-modal';

class WeaponContainer extends React.Component {
    state = {
      isOpen: false,
    }

    openModal = () => {
      this.setState({ isOpen: true });
    }

    closeModal = () => {
      this.setState({ isOpen: false });
    }

    render () {
      return (
        <div>
          <TestInvitation openModal={this.openModal} />
          <Modal
            isOpen={this.state.isOpen}
            onRequestClose={this.closeModal}
            shouldCloseOnEsc
            shouldCloseOnOverlayClick
            style={{ overlay: { zIndex: 3000 } }}
            className="test-wrapper"
          >
            <WeaponTest closeModal={this.closeModal} />
          </Modal>
        </div>
      );
    }
}

export default WeaponContainer;
